@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,500;1,800&display=swap");

:root {
  --white: #fff;
  --black: #000;
  --light_black: #212121;
  --pink: #ff8f9c;
  --gray: #787878;
  --light_gray: #f2f2f2;
  --heavy_gray: #545454;
  --bittersweet: #f66;
  --onyx: #454545;
  --sonic_silver: #787878;
  --border_bottom:1.5px solid var(--light_gray);
}
@mixin flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin flex_around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex_column {
  display: flex;
 flex-direction: column;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
  /* transition: 0.4s; */
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  /* overflow: hidden; */
  font-family: "Poppins", sans-serif;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}
.proj_container {
  width: 90%;
  margin: auto;
}
.accordion {
  padding: 0 !important;
}
@media (max-width: 1024px) {
  .proj_container {
    width: 93% !important;
    margin: auto;
  }
}
@media (max-width: 992px) {
  .proj_container {
    width: 95% !important;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .proj_container {
    width: 97% !important;
    margin: auto;
  }
}
