@import "../../index.scss";
.SideCategories {
    .allScreenFixedSide {
        .fixedSide {
            position: absolute;
            width: 300px;
            padding: 20px ;
            transition: 0.3s;
            transition-property: left;
            top: 0;
            left: -300px;
            bottom: 0;
            background-color: var(--white);
            z-index: 0;
            box-shadow: none;
            .fixedSideContent {
                .MobileCategories__mainHeading {
                    @include flex_between;
                    border-bottom: 2px solid var(--light_gray);
                    padding-bottom: 10px;
                    svg {
                        font-size: 22px;
                        cursor: pointer;
                        transition: 0.2s;
                        &:hover {
                            color: var(--pink);
                        }
                    }
                    h3 {
                       
                        font-size: 1.2rem;
                        text-transform: uppercase;
                        letter-spacing: 0.8px;
                        font-weight: 700;
                        color: var(--onyx) !important;
                    }
                }
                ul {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                 li{
                    padding-top: 20px ; 
                 }
                    .accordion {
                        --bs-accordion-btn-padding-y: 0 !important;
                        --bs-accordion-btn-padding-x: 0 !important;
                        --bs-accordion-color: var(--sonic_silver) !important;
                        --bs-accordion-active-color: var(--light_black) !important;
                        --bs-accordion-btn-icon: url("../../assets/plus.svg") !important;
                        --bs-accordion-btn-icon-transform: rotate(180deg) !important;
                        --bs-accordion-btn-icon-transition: transform 0.4s ease-in-out !important;
                        --bs-accordion-btn-active-icon: url("../../assets/minus.svg") !important;
                        --bs-accordion-btn-icon-width: 1rem !important;
                        li {
                            font-size: 1rem;
                            color: var(--sonic_silver);
                            font-weight: 600;
                            .accordion_item {
                                border: none;
                                .accordion_header {
                                    button {
                                        color: var(--sonic_silver);
                                        font-size: 1rem;
                                        background-color: transparent;
                                        font-weight: 500 !important ;
                                        margin-top: 0px;
                                        @include flex_between;
                                        width: 100%;
        
                                        .accordion_header__img {
                                            width: 9%;
                                            img {
                                                width: 100%;
                                            }
                                        }
                                        p {
                                            margin-bottom: 0;
                                            margin-left: 10px;
                                        }
                                        ::after {
                                            color: red;
                                            font-size: 5rem;
                                        }
                                        &:focus {
                                            border: none !important;
                                            box-shadow: none !important;
                                            background-color: transparent;
                                        }
                                    }
                                }
                                div {
                                    .accordion-body {
                                        padding: 0 !important;
                                        margin-top: 15px;
        
                                        ul {
                                            padding: 0 !important;
        
                                            li {
                                                transition: 0.3s;
                                                cursor: pointer;
                                                font-weight: normal;
                                                font-size: 16px;
                                                margin-top: 2px;
                                                @include flex_between;
                                                border:none;
                                                padding: 5px 0 0 0;
                                               
                                                p {
                                                    margin-bottom: 0;
                                                }
                                                &:hover {
                                                    color: var(--light_black);
                                                }
                                                &:last-child {
                                                    border-bottom: var(--border_bottom);
                                                    padding-bottom: 15px;
                                                    margin-top: 7px;
                                                }
                                                &:first-child {
                                                    margin-top: 0px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}



















.open_fixedSide {
    transition-property: left;
    z-index: 3 !important;
    box-shadow: 0px 5px 15px 1200px rgba(0, 0, 0, 0.35) !important;
    left: 0 !important;
}
.open_allScreenFixedSide {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    z-index: 5;
}
// ***************************************************************************************************
// *******************************************media queries*******************************************
// ***************************************************************************************************
@media (max-width: 1024px) {
    .MobileNav {
        .allFixedDown {
            display: block;
        }
    }
}
@media (max-width: 500px) {
    .MobileNav {
        .allFixedDown {
            .fixedDown {
                width: 100%;
            }
        }
    }
}
