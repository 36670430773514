@import "../../index.scss";
.Header {
    padding: 20px 0;
    width: 100% !important;
    border-bottom: var(--border_bottom);
    .HeaderContent {
        @include flex_between;
        .logo {
            width: 14%;
            img {
                width: 100%;
            }
        }
        .searchBar {
            width: 65%;
            flex-shrink: 30;
            position: relative;

            input {
                width: 100%;
                border: 1px solid var(--light_gray);
                border-radius: 10px;
                padding: 10px 15px;
                &:focus {
                    border: 1px solid var(--pink);
                }
            }
            svg {
                position: absolute;
                right: 20px;
                top: 15px;
                font-size: 20px;
            }
        }
        .personalData {
            @include flex_between;
            width: 12%;
            div {
                transition: 0.3s;
                position: relative;
                &:hover {
                    color: var(--pink);
                }
                svg {
                    font-size: 29px;
                    cursor: pointer;
                }
                span {
                    @include flex_center;
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    background-color: var(--bittersweet);
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
    }
}

// ***************************************************************************************************
// *******************************************media queries*******************************************
// ***************************************************************************************************
@media (max-width: 1024px) {
    .Header {
        .HeaderContent {
            .logo {
                width: 19%;
            }
            .searchBar {
                width: 75%;
            }
            .personalData {
                display: none;
            }
        }
    }
}
@media (max-width: 576px) {
    .Header {
        padding: 0;
        padding-top: 10px;
        .HeaderContent {
            .logo {
                display: none;
            }
            .searchBar {
                width: 100%;
            }
        }
    }
}
