@import "../../index.scss";
.CategoriesList {
    width: 60%;
    margin: auto;
    padding: 15px 0 0 0;
    ul {
        @include flex_between;
        margin-bottom: 0;
        padding-left: 0;
        li {
            text-transform: uppercase;
            font-weight: 700;
            color: var(--onyx);
            transition: 0.4s;
            font-size: 16px;
            margin-bottom: 5px;
            padding-bottom: 10px;
            position: relative;
            z-index: 2;
            transition: 0.4s;

            &::before {
                position: absolute;
                top: 35px;
                transition: 0.4s;
                content: "";
                width: 0;
                height: 3px;
                background-color: var(--pink);
            }
            &:hover {
                color: var(--pink);
                cursor: pointer;
                &::before {
                    width: 100%;
                }
                ul {
                    top: 40px;
                    transition: 0.4s;
                    display: block;
                    background-color: var(--white);
                }
            }
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 7px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                position: absolute;
                width: 200px;
                left: 0;
                top: 80px;
                transition: 0.4s;
                padding: 20px;
                border: none !important;
                display: none;

                li {
                    font-weight: normal;
                    font-size: 17;
                    text-transform: capitalize;
                    color: var(--sonic_silver);
                    &:hover {
                        &::before {
                            width: 0%;
                        }
                    }
                }
            }
            .SideListClass {
                display: none;
                position: absolute;
                width: 900%;
                left: -100px;
                // left: -320%;
                top: 280px;
                padding: 25px;
                border: 1px solid var(--light_gray);
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                border-radius: 7px;
                transition: 0.4s;
                .allLists {
                    @include flex_between;
                    flex-direction: row;

                    div {
                        display: block;
                        width: 23%;
                        p {
                            &:first-child {
                                font-weight: 700;
                                padding-bottom: 15px;
                                border-bottom: 2px solid var(--light_gray);
                                color: var(--black);
                                font-size: 17px;
                                text-transform: capitalize;
                                &:hover {
                                    color: var(--black);
                                    cursor: default;
                                }
                            }

                            color: var(--sonic_silver);
                            font-size: 17px;
                            text-transform: capitalize;
                            transition: 0.2s;
                            &:hover {
                                color: var(--pink);
                                cursor: pointer;
                            }
                        }
                        img {
                            margin-top: 20px;
                            border-radius: 7px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// ***************************************************************************************************
// *******************************************media queries*******************************************
// ***************************************************************************************************

@media (max-width: 1200px) {
    .CategoriesList {
        width: 70%;
    }
}
@media (max-width: 1024px) {
    .CategoriesList {
        display: none;
    }
}
